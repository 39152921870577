.swiper-pagination-bullet {
  width: 8px !important;
  height: 8px !important;
  border-radius: 4px !important;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
  transition: width 0.2s;
}

.swiper-pagination-bullet-active {
  width: 30px !important;
  height: 8px !important;
  color: #fff;
  background: #df3e23 !important;
  transition: width 0.2s;
}

/* .swiper-pagination {
    width: 100px !important;
} */

.cls-box-header h1 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 0px;
  text-align: center;
}
.cls-box-header h6 {
  font-size: 14px;
  font-weight: 100;
}

.cls-box-search {
  margin-top: 30px;
  width: 98%;
  max-width: 420px;
}
.cls-box-search > div {
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
  width: 98%;
}
.cls-box-search > div .cls-tracking {
  width: 100%;
  height: 100%;
  max-width: 115px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-weight: 500;
}

.cls-box-search > div .cls-tracking,
.cls-box-search > div .MuiSelect-select {
  min-width: 70px;
  background-color: #c03d34;
  border-radius: 0;
  color: #fff;
}
.cls-box-search > div .MuiTextField-root {
  width: 100%;
}
.cls-box-search fieldset {
  display: none;
}

.block-process-order {
  box-shadow: 0px 0px 12px rgba(15, 61, 145, 0.12);
  width: 200px;
  padding: 15px;
  border-radius: 10px;
}
.block-process-order img {
  display: block;
  margin-bottom: 25px;
}
.block-process-order h3 {
  font-size: 18px;
  color: #1f204d;
  font-weight: 600;
  margin-bottom: 15px;
}
.block-process-order p {
  color: #848589;
  font-size: 14px;
}

.content-html * {
  font-family: 'Montserrat' !important;
  max-width: 100%;
}
