@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Montserrat;
  src: url(../public/fonts/Montserrat-VariableFont_wght.ttf);
}

body {
  font-family: 'Montserrat';
  min-width: 320px;
}
